// src/components/Navbar.js
import React from 'react';
import { Box, Flex, HStack, IconButton, useDisclosure, Stack, Image, Button, Text } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useAuth } from '../context/AuthContext';
import Logo from '../images/Tiny Triumphs Hori.png';

const NavLink = ({ to, children, onClick, isScroll = false }) => {
  if (isScroll) {
    return (
      <ScrollLink to={to} smooth={true} duration={500} spy={true} offset={-70} onClick={onClick}>
        <Text cursor="pointer" px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
          {children}
        </Text>
      </ScrollLink>
    );
  }

  return (
    <RouterLink to={to}>
      <Text cursor="pointer" px={2} py={1} rounded="md" _hover={{ bg: 'gray.200' }}>
        {children}
      </Text>
    </RouterLink>
  );
};

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  const { user,  profile, logout } = useAuth();

  const handleToggle = () => (isOpen ? onClose() : onOpen());

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/sign-in');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const renderAuthenticatedLinks = () => (
    <>
      <NavLink to="/">Home</NavLink>
      <NavLink to={`/${profile?.role}-dashboard`}>Dashboard</NavLink>
    </>
  );

  const renderUnauthenticatedLinks = () => (
    location.pathname === '/register' ? (
      <>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/">About Us</NavLink>
        <NavLink to="/">Our Services</NavLink>
        <NavLink to="/">Our Facilities</NavLink>
        <NavLink to="/">Contact Us</NavLink>
      </>
    ) : (
      <>
        <NavLink to="hero" isScroll={true}>Home</NavLink>
        <NavLink to="about-us" isScroll={true}>About Us</NavLink>
        <NavLink to="our-services" isScroll={true}>Our Services</NavLink>
        <NavLink to="our-facilities" isScroll={true}>Our Facilities</NavLink>
        <NavLink to="contact-us" isScroll={true}>Contact Us</NavLink>
      </>
    )
  );

  return (
    <Box bg="white" px={4} boxShadow="md" position="sticky" top={0} zIndex={10}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <HStack spacing={8} alignItems="center">
          <Box>
            <RouterLink to="/">
              <Image src={Logo} alt="Tiny Triumphs Logo" height="50px" />
            </RouterLink>
          </Box>
          <HStack as="nav" spacing={4} display={{ base: 'none', md: 'flex' }}>
            {user ? renderAuthenticatedLinks() : renderUnauthenticatedLinks()}
          </HStack>
        </HStack>

        {user ? (
          <Button
            onClick={handleLogout}
            colorScheme="red"
            variant="ghost"
            size="md"
            ml="auto"
            display={{ base: 'none', md: 'block' }}
          >
            Logout
          </Button>
        ) : (
          <RouterLink to="/register">
            <Button
              bgColor="#afda2d"
              color="white"
              size="md"
              ml="auto"
              display={{ base: 'none', md: 'block' }}
            >
              Join the Waitlist
            </Button>
          </RouterLink>
        )}

        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: 'none' }}
          onClick={handleToggle}
          ml={2}
        />
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as="nav" spacing={4}>
            {user ? renderAuthenticatedLinks() : renderUnauthenticatedLinks()}
            {user ? (
              <Button colorScheme="red" variant="ghost" onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <RouterLink to="/register" onClick={handleToggle}>
                <Button 
                  bgColor="#afda2d"
                  color="white"
                  width="full"
                >
                  Join the Waitlist
                </Button>
              </RouterLink>
            )}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default Navbar;