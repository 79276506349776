import React from 'react';
import { Box, Container, Heading, Text, VStack, Link } from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Container maxW="container.lg" py={10}>
      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="xl">Privacy Policy</Heading>
        
        <Text>Last updated: February 23, 2025</Text>
        
        <Box>
          <Heading as="h2" size="lg" mb={4}>1. Information We Collect</Heading>
          <Text mb={4}>
            We collect information that you provide directly to us when using our application:
          </Text>
          <Text mb={2}>• Personal Information: Name, email address, and contact information</Text>
          <Text mb={2}>• Account Information: Login credentials and user preferences</Text>
          <Text mb={2}>• Usage Data: How you interact with our application</Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>2. How We Use Your Information</Heading>
          <Text mb={4}>We use the collected information for the following purposes:</Text>
          <Text mb={2}>• To provide and maintain our service</Text>
          <Text mb={2}>• To notify you about changes to our service</Text>
          <Text mb={2}>• To provide customer support</Text>
          <Text mb={2}>• To detect, prevent, and address technical issues</Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>3. Data Security</Heading>
          <Text mb={4}>
            We implement appropriate security measures to protect your personal information. 
            However, no method of transmission over the Internet is 100% secure, and we cannot 
            guarantee absolute security.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>4. Third-Party Services</Heading>
          <Text mb={4}>
            Our application may contain links to third-party websites or services. We are not 
            responsible for the privacy practices of these third-party services.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>5. Children's Privacy</Heading>
          <Text mb={4}>
            Our service is not intended for use by children under the age of 13. We do not 
            knowingly collect personal information from children under 13.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>6. Changes to This Privacy Policy</Heading>
          <Text mb={4}>
            We may update our Privacy Policy from time to time. We will notify you of any 
            changes by posting the new Privacy Policy on this page and updating the "Last 
            updated" date.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>7. Contact Us</Heading>
          <Text mb={4}>
            If you have any questions about this Privacy Policy, please contact us at:
          </Text>
          <Text>tinytriumphsph@gmail.com</Text>
          <Text>3/F Allegro Center, 2284 Don Chino Roces Ave. Ext., Magallanes, Makati, Philippines</Text>
        </Box>
      </VStack>
    </Container>
  );
};

export default PrivacyPolicy;