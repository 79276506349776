import React from 'react';
import { 
  Box, 
  Container, 
  Heading, 
  Text, 
  VStack, 
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Link,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast
} from '@chakra-ui/react';

const Support = () => {
  const toast = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    toast({
      title: "Support ticket submitted.",
      description: "We'll get back to you as soon as possible.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Container maxW="container.lg" py={10}>
      <VStack spacing={8} align="stretch">
        <Heading as="h1" size="xl">Support Center</Heading>

        {/* Quick Help Section */}
        <Box>
          <Heading as="h2" size="lg" mb={4}>Quick Help</Heading>
          <Accordion allowToggle>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How do I reset my password?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                Click on the "Forgot Password" link on the sign-in page. Enter your email address, 
                and we'll send you instructions to reset your password.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How do I update my profile?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                Navigate to your dashboard and click on the profile settings icon. 
                There you can update your personal information and preferences.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    What should I do if I encounter an error?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                Try refreshing the page first. If the error persists, clear your browser cache 
                and cookies. If you're still experiencing issues, please contact our support team.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>

        {/* Contact Form */}
        <Box>
          <Heading as="h2" size="lg" mb={4}>Contact Support</Heading>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input type="text" placeholder="Your name" />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input type="email" placeholder="your@email.com" />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Subject</FormLabel>
                <Input type="text" placeholder="What is your inquiry about?" />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Message</FormLabel>
                <Textarea placeholder="Please describe your issue in detail" />
              </FormControl>

              <Button type="submit" colorScheme="blue" width="full">
                Submit Support Ticket
              </Button>
            </VStack>
          </form>
        </Box>

        {/* Additional Contact Information */}
        <Box>
          <Heading as="h2" size="lg" mb={4}>Additional Support Options</Heading>
          <Text mb={2}>Email: support@tinytriumphs.ph</Text>
          <Text mb={2}>Phone: +639178501488</Text>
          <Text mb={2}>Hours: Monday - Friday, 9:00 AM - 5:00 PM GMT+8</Text>
        </Box>
      </VStack>
    </Container>
  );
};

export default Support;