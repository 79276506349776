import React from 'react';
import { Box, Container, Stack, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box as="footer" bg="#f7af28" py={4}>
      <Container maxW="container.lg">
        <Stack direction={['column', 'row']} spacing={4} justify="center" align="center">
          <ChakraLink as={RouterLink} to="/privacy-policy">
            Privacy Policy
          </ChakraLink>
          <ChakraLink as={RouterLink} to="/support">
            Support
          </ChakraLink>
          <Text>© {new Date().getFullYear()} Tiny Triumphs. All rights reserved.</Text>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;