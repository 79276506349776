import React from 'react';
import { Box, Heading, Text, Button, VStack, Image, Flex, Icon, SimpleGrid } from '@chakra-ui/react';
import { FaHandsHelping, FaBrain, FaChild, FaPaintBrush, FaBuilding } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <Box>
      {/* Hero Section */}
      <Box position="relative" id="hero">
        <Box
          bgImage="/Playground.JPEG"
          bgSize="cover"
          bgPosition="center"
          color="white"
          py={{ base: '80px', md: '120px' }}
          textAlign="center"
          position="relative"
          zIndex={1}
        >
          <Box position="absolute" top={0} left={0} right={0} bottom={0} bg="rgba(0, 0, 0, 0.5)" zIndex={-1}></Box>
          <Heading as="h1" fontSize={{ base: '3xl', md: '5xl' }} textShadow="2px 2px 4px rgba(0, 0, 0, 0.7)">
            Welcome to Tiny Triumphs Therapy Center
          </Heading>
          <Text fontSize={{ base: 'md', md: 'lg' }} mt={4} textShadow="1px 1px 2px rgba(0, 0, 0, 0.7)">
            Nurturing Growth, One Child at a Time
          </Text>
          <Link to="/register">
            <Button bgColor="#afda2d" color="white" size="lg" mt={8}>
              Join the Waitlist
            </Button>
          </Link>
        </Box>
      </Box>

      {/* About Section */}
      <Box py={{ base: '40px', md: '60px' }} px={{ base: '20px', md: '40px' }} textAlign="center" id="about-us">
        <Heading fontSize={{ base: '2xl', md: '4xl' }}>About Us</Heading>
        <Text fontSize={{ base: 'md', md: 'lg' }} mt={4}>
          At our center, we focus on the holistic development of each child, providing personalized therapy sessions
          and a nurturing environment.
        </Text>
        <Image
          src="/Eze.JPEG"
          alt="Children engaged in a therapy session at Tiny Triumphs Therapy Center"
          mt={8}
          borderRadius="lg"
          shadow="md"
        />
      </Box>

      {/* Services Section */}
      <Box bg="gray.100" py={{ base: '40px', md: '60px' }} px={{ base: '20px', md: '40px' }} textAlign="center" id="our-services">
        <Heading fontSize={{ base: '2xl', md: '4xl' }}>Our Services</Heading>
        <Flex wrap="wrap" justify="center" mt={8} spacing={8}>
          <VStack bg="white" p={5} borderRadius="lg" shadow="md" m={4} maxW="300px">
            <Icon as={FaHandsHelping} w={10} h={10} color="teal.500" />
            <Heading fontSize="xl" mt={4}>Occupational Therapy</Heading>
            <Text mt={2} fontSize="md">
              Helping children develop the skills they need for daily living.
            </Text>
          </VStack>
          <VStack bg="white" p={5} borderRadius="lg" shadow="md" m={4} maxW="300px">
            <Icon as={FaBrain} w={10} h={10} color="teal.500" />
            <Heading fontSize="xl" mt={4}>Speech Therapy</Heading>
            <Text mt={2} fontSize="md">
              Enhancing communication skills and language development.
            </Text>
          </VStack>
          <VStack bg="white" p={5} borderRadius="lg" shadow="md" m={4} maxW="300px">
            <Icon as={FaChild} w={10} h={10} color="teal.500" />
            <Heading fontSize="xl" mt={4}>Physical Therapy</Heading>
            <Text mt={2} fontSize="md">
              Improving physical abilities and promoting mobility.
            </Text>
          </VStack>
          <VStack bg="white" p={5} borderRadius="lg" shadow="md" m={4} maxW="300px">
            <Icon as={FaPaintBrush} w={10} h={10} color="teal.500" />
            <Heading fontSize="xl" mt={4}>Play School</Heading>
            <Text mt={2} fontSize="md">
              Socialization, arts and crafts, little scientists, and more.
            </Text>
          </VStack>
        </Flex>
      </Box>

      {/* Facilities Section */}
      <Box py={{ base: '40px', md: '60px' }} px={{ base: '20px', md: '40px' }} textAlign="center" id="our-facilities">
        <Heading fontSize={{ base: '2xl', md: '4xl' }}>Our Facilities</Heading>
        <Text fontSize={{ base: 'md', md: 'lg' }} mt={4}>
          Our facilities are equipped with various activities to provide the best experience for our clients.
        </Text>

        <Box mt={8}>
          <Image
            src="/Playground.JPEG"
            alt="Modern and well-equipped therapy facilities at Tiny Triumphs"
            borderRadius="lg"
            shadow="md"
            mb={6}
          />
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            <Image
              src="/Reception.JPEG"
              alt="Warm and welcoming reception area at Tiny Triumphs"
              borderRadius="lg"
              shadow="md"
            />
            <Image
              src="/Hallway.JPEG"
              alt="Bright and inviting hallway leading to therapy rooms"
              borderRadius="lg"
              shadow="md"
            />
          </SimpleGrid>
        </Box>
      </Box>

      {/* Contact Section */}
      <Box bg="#34b4e2" color="white" py={{ base: '40px', md: '60px' }} px={{ base: '20px', md: '40px' }} textAlign="center" id="contact-us">
        <Heading fontSize={{ base: '2xl', md: '4xl' }}>Get in Touch</Heading>
        <Text fontSize={{ base: 'md', md: 'lg' }} mt={4}>
          We'd love to hear from you. Contact us for more information.
        </Text>
        <VStack mt={8} spacing={4}>
          <Text fontSize={{ base: 'md', md: 'lg' }}>
            <strong>Address:</strong> 3rd Flr, Allegro Center, Pasong Tamo Ext., Makati
          </Text>
          <Text fontSize={{ base: 'md', md: 'lg' }}>
            <strong>Email:</strong> jo@tinytriumphs.ph
          </Text>
          <Text fontSize={{ base: 'md', md: 'lg' }}>
            <strong>Phone:</strong> (0945) 133-8324
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};

export default Home;
